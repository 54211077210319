/* global ajax_object */

import UIkit from 'uikit';

export default {
  init() {
    // JavaScript to be fired on all pages
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    $( document ).ready(function() {
      setTimeout(function() {
        $('body').css('visibility','');
      },)
    });

    // UIkit.offcanvas('#wow-menu').show();

  },
};