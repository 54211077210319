import Vue from 'vue'
import axios from 'axios'
import Vuelidate from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

Vue.use(Vuelidate)

let paxDefault = {
    name: '',
    gender: '',
    nationality: '',
    height: '',
    birth_day: '',
    birth_month: '',
    birth_year: '',
}
let defalutFormData = {
    pax: [...Array(8)].map( (_, i) => { return { ...paxDefault } } ),
    phone_number: '',
    tour_name: '',
    email: '',
    tour_date_day: '',
    tour_date_month: '',
    tour_date_year: '',
    tour_date_time: '',
    more_info: '',
    tour_name: '',
    tour: '',
    action: 'booking_tour',
    _wpnonce: window.ajax_object.ajax_nonce
}

const appBookingEl = document.getElementById("appBooking")
if (appBookingEl) {
    const appBooking = new Vue ({
        el: '#appBooking',
        data: {
            formData: defalutFormData,
            isSubmiting: false
        },
        validations: {
            formData: {
                pax:{
                    [0]:{
                        name: {
                            required
                        },
                        gender: {
                            required
                        },
                        nationality: {
                            required
                        },
                        height: {
                            required
                        },
                        birth_day: {
                            required
                        },
                        birth_month: {
                            required
                        },
                        birth_year: {
                            required
                        },
                    }
                },
                tour_date_day: {
                    required
                },
                tour_date_month: {
                    required
                },
                tour_date_year: {
                    required
                }
            },
            validationDateTour: ['formData.tour_date_year', 'formData.tour_date_month', 'formData.tour_date_day']
        },
        mounted() {
            this.formData.tour_name = this.$refs.tour_name.dataset.value;
            this.formData.tour = this.$refs.tour.dataset.value;
        },
        methods: {
            onSubmitBooking(e) {
                e.preventDefault()
                this.$v.$touch()
                if (this.$v.$invalid) {
                    return;
                }
                this.isSubmiting = true
                const formData = new FormData()
                Object.keys(this.formData).map( key => {
                    if(key === 'pax'){
                        //Build pax formData
                        this.formData[key].filter( pax => pax.name.trim() !== '' )
                        .map((pax, i) => {
                            Object.keys(pax).map( paxKey => {
                                formData.append(`pax[${i}][${paxKey}]`, pax[paxKey] )
                            } )
                            const birthDate = `${pax['birth_day']} ${pax['birth_month']} ${pax['birth_year']}`.trim()
                            formData.append(`pax[${i}][birthday]`, birthDate )
                        })
                    }else{
                        formData.append(key, this.formData[key])
                    }
                } )
                formData.append('name', this.formData.pax[0].name)
                axios.post(ajax_object.ajax_url, formData)
                .then((res) => {
                    if(res.data && res.data.success){
                        window.location.href = `${window.ajax_object.base_url}/payment?booking_id=${res.data.booking_id}&email=${res.data.email}`
                        return
                    }else if(res.data.message) {
                        alert(res.data.message)
                    }
                    this.isSubmiting = false
                })
                .catch((res)=>{

                })
                
                
            }
        },
    })
}