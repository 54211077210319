import Vue from 'vue'

const appTourEl = document.getElementById("appTour")
if(appTourEl){
    const appTour = new Vue({
        el: '#appTour',
        data: {
            isAgreeCondition: false
        },
        mounted() {
        },
        methods: {
            onSubmit(e){
                if(!this.isAgreeCondition){
                    e.preventDefalut()
                }
            }
        },
    })
}
