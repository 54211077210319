// import external dependencies
import $ from 'jquery';

// Import everything from autoload
import './autoload/_jquery.csbuttons.js'; import './autoload/_mCustomScrollbar.js'; import './autoload/_uikit.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';

import './containers/booking';
import './containers/tour';

window.$ = $;

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
});

$(document).ready(function () {
  $('.csbuttons').cSButtons();
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());